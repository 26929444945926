/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-handler-names */
import React from 'react'
import ReactToPrint from 'react-to-print'
import math from 'mathjs-expression-parser'
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button } from 'react-bootstrap'
import { ComponentToPrint } from './components/print-pedido'
import { SeccionPedidos } from './components/SeccionPedidos'
import { userService } from '../_services'
import cuadraturaService from '../_services/cuadratura'
import demoUsers from '../constants/demo-users'
import './styles.scss'
import { SeccionCaja } from './components/SeccionCaja'
import { SeccionReporte } from './components/SeccionReporte'
import { homeService } from '../_services/home'

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    if (localStorage.getItem('user').charAt(0) !== '{') {
      this.props.history.push('/login')
    }
    this.state = {
      productos: [],
      carrito: JSON.parse(localStorage.getItem('carrito')),
      user: JSON.parse(localStorage.getItem('user')),
      rol: localStorage.getItem('rol'),
      filter: '',
      descuento: 0,
      montofinal: 0,
      cola: JSON.parse(localStorage.getItem('cola')),
      efectivo:
        JSON.parse(localStorage.getItem('carrito')).reduce(
          (prev, cur) => prev + parseInt(cur.precio * cur.Cantidad),
          0
        ) ?? 0,
      formula: '',
      subbedFormula: '',
      datosempresa: '',
      folio: '',
      cliente: '',
      curtime: new Date().toLocaleString(),
      token: JSON.parse(localStorage.getItem('user')),
      bodega: [{ id: 0, nombre: 'Cargando' }],
      formaspago: [{ id: 0, nombre: 'Cargando' }],
      gruposPago: [],
      inputGrupo: '',
      vendedores: [{ id: 0, nombre: 'Cargando' }],
      inputPago: '',
      anoreporte: new Date().getFullYear(),
      mesreporte: this.convertMonth(new Date().getMonth() + 1),
      inputBodega: '',
      inputVendedor: '',
      inputAreaNegocio: '',
      areaNegocio: [{ id: 0, nombre: 'Cargando' }],
      loading: true,
      cargandoboleta: false,
      loadingreporte: false,
      reportediario: {},
      categorias: [],
      toaststyle: { position: toast.POSITION.TOP_CENTER },
      visualizacion: 'default',
      timbre: '',
      sincronizando: false,
      TipoDocumento: 'BOLETA CON DETALLE',
      vistapos: true,
      combos: [],
      categoriaseleccionada: '',
      comboseleccionado: '',
      nombrecategoria: '',
      nombresubcategoria: '',
      nombreproducto: '',
      tipoproducto: '',
      precio_regular: '',
      precio_oferta: '',
      arrayitems: [],
      buscarproductos: false,
      seleccionvariable: false,
      nuevaopcion: '',
      arrayopciones: [],
      cantidadopcion: 0,
      activaredicion: false,
      visualizarlistadoproductos: '',
      tiponuevoproducto: '',
      temporalproducto: false,
      cat: false,
      loadingPedidos: true,
      pedidos: [],
      veinte: '0',
      diez: '0',
      cinco: '0',
      dos: '0',
      mil: '0',
      quinientos: '0',
      cien: '0',
      cincuenta: '0',
      loadingCuadratura: false,
      tipopago: 'efectivo',
      ultimacuadratura: false,
      nuevacuadratura: false,
      step: 1,
      inputSerie: '',
      inputBanco: '',
      ///draft
      pedido: null,
    }

    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeProducto = this.handleChangeProducto.bind(this)
    this.handleChangeReporte = this.handleChangeReporte.bind(this)
    this.handleChangeState = this.handleChangeState.bind(this)
    this.handleChangeOpcion = this.handleChangeOpcion.bind(this)
    this.user = JSON.parse(localStorage.getItem('user'))
  }
  // useEffect(() => {
  //   this.state.role = localStorage.getItem('rol')
  // }, [])

  handleChangeState(e) {
    const { name, value } = e.target
    if (name === 'tipoproducto') {
      if (value === 'simple' || value === 'agrupado') {
        this.setState({ arrayitems: [] })
      }
    }
    this.setState({ [name]: value })
  }

  handleChangeOpcion(e) {
    const { name, value } = e.target
    const cantidad = e.target.getAttribute('cantidad')
    const iopt = e.target.getAttribute('iopt')
    const io = e.target.getAttribute('io')
    const { seleccionvariable } = this.state

    let sumar = 0
    seleccionvariable.opciones[iopt].opciones[io].cantidad = value
    // const cuantosactivos = seleccionvariable.opciones[iopt].opciones.map(item => {
    //   const qty = item.cantidad ? parseInt(item.cantidad) : 0
    //   sumar = sumar + qty
    // })
    if (sumar > cantidad)
      return toast.error(`No puedes escoger más de ${cantidad} ${name} `, this.state.toaststyle)

    return this.setState({ seleccionvariable })
  }

  visualizarProductos(producto) {
    const { activaredicion } = this.state
    if (!producto.productos) return this.productosVacios()

    if (producto.productos.length < 1) return this.productosVacios('vacio')

    return (
      <div className='productscontainer'>
        {this.mostrarOpcionesProductoAgrupado()}
        {producto.productos.map((producto, ipro) => {
          return (
            <div
              key={ipro}
              className='boxproductopos'
              onClick={() => this.agregarComboACarrito(producto)}
            >
              <div className='boximagen'>
                <img alt='producto' src='producto.png' />
              </div>
              <div className='p-4'>
                <h3 className='nopadding'>
                  <b>{producto.titulo}</b>
                </h3>
                <h3 className='nopadding precio'>
                  $
                  {producto.precio_oferta
                    ? Intl.NumberFormat(['ban', 'id']).format(producto.precio_oferta)
                    : Intl.NumberFormat(['ban', 'id']).format(producto.precio_regular)}
                </h3>
                {activaredicion === true ? (
                  <button
                    className='btn btn-eliminar mb-4'
                    onClick={() => this.eliminarElemento(producto._id['$oid'], 'producto')}
                  >
                    ELIMINAR
                  </button>
                ) : (
                  false
                )}
                {producto.detalles
                  ? producto.detalles.map((prro, iprro) => {
                      return <span key={iprro}>{prro.titulo}</span>
                    })
                  : false}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  productosVacios(tipo) {
    if (!tipo)
      return (
        <div className='contener'>
          <img alt='claping' src='claping.png' style={{ width: 100 }} />
          <h4>No hay nada seleccionado</h4>
        </div>
      )

    if (tipo === 'vacio')
      return (
        <div>
          <img alt='imagen vacia' src='empty.png' style={{ width: 100 }} />
          <h4>No hay productos</h4>
        </div>
      )
  }

  agentSuperAdmin() {
    this.state.rol = localStorage.getItem('rol')
    const { visualizacion } = this.state
    if (localStorage.getItem('email') == 'desarrollo@softnet.cl') {
      return (
        <>
          <Button
            size='sm'
            variant={visualizacion === 'reporte' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('reporte')
            }}
          >
            REPORTE
          </Button>
          <Button
            size='sm'
            style={{ marginLeft: 12 }}
            variant={visualizacion === 'caja' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('caja')
            }}
          >
            CAJA
          </Button>
          <Button
            size='sm'
            style={{ marginLeft: 12 }}
            variant={visualizacion === 'pedidos' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('pedidos')
            }}
          >
            PEDIDOS
          </Button>
        </>
      )
    } else {
      if (this.state.rol === 'ADMIN') {
        return (
          <Button
            size='sm'
            variant={visualizacion === 'reporte' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('reporte')
            }}
          >
            REPORTE
          </Button>
        )
      } else if (this.state.rol === 'VENDEDOR') {
        return (
          <Button
            size='sm'
            style={{ marginLeft: 12 }}
            variant={visualizacion === 'caja' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('caja')
            }}
          >
            CAJA
          </Button>
        )
      } else if (this.state.rol === 'ENTREGAR_PEDIDO') {
        return (
          <Button
            size='sm'
            style={{ marginLeft: 12 }}
            variant={visualizacion === 'pedidos' ? 'success' : 'primary'}
            onClick={() => {
              this.setVisualizacion('pedidos')
            }}
          >
            PEDIDOS
          </Button>
        )
      }
    }
  }

  mostrarProductosCombo(filtro) {
    const { combos, categoriaseleccionada, activaredicion, comboseleccionado } = this.state
    if (!categoriaseleccionada) return this.productosVacios()
    if (!comboseleccionado) return this.productosVacios()

    /*
        if(!filtro){
            if(Array.isArray(combos)){
                if(combos.length > 0){
                    return this.visualizarProductos(combos[0])
                }
            }
            return false
        }
        */

    const findi = combos.findIndex(ca => ca._id['$oid'] === categoriaseleccionada)

    if (findi > -1) {
      if (!combos[findi].categorias) return this.productosVacios()

      const posicionproductos = combos[findi].categorias.findIndex(
        ca => ca._id['$oid'] === comboseleccionado
      )

      if (posicionproductos > -1) {
        if (!combos[findi].categorias[posicionproductos].productos) return this.productosVacios()

        return (
          <div className='contener'>
            <button
              className='btn btn-agregar mb-4 mr-4'
              onClick={() => this.setState({ visualizacion: 'nuevoproducto' })}
            >
              NUEVO PRODUCTO
            </button>
            <button
              className='btn btn-editar mb-4 mr-4'
              onClick={() => this.setState({ activaredicion: activaredicion ? false : true })}
            >
              EDITAR
            </button>
            <button
              className='btn btn-eliminar mb-4'
              onClick={() =>
                this.eliminarElemento(
                  combos[findi].categorias[posicionproductos]._id['$oid'],
                  'subcategoria'
                )
              }
            >
              ELIMINAR
            </button>

            {this.visualizarProductos(combos[findi].categorias[posicionproductos])}
          </div>
        )
      }
    }

    return false
  }

  mostrarSubCategoria(categoriaseleccionada) {
    const { combos, comboseleccionado } = this.state
    if (!categoriaseleccionada) return false

    const findi = combos.findIndex(ca => ca._id['$oid'] === categoriaseleccionada)

    if (findi > -1) {
      if (!combos[findi].categorias) return false

      return (
        <div className='contener'>
          <h1 className='subtitulo'>
            {combos[findi].titulo}{' '}
            <button
              className='btn btn-eliminar'
              onClick={() => this.eliminarElemento(combos[findi]._id['$oid'], 'categoria')}
            >
              ELIMINAR
            </button>{' '}
          </h1>

          <div className='scrollmenu2'>
            <span className='porseleccionar' onClick={() => this.nuevaSubCategoria()}>
              <i className='fas fa-plus-circle'></i> NUEVA SUBCATEGORIA
            </span>
            {combos[findi].categorias.map((combo, icombo) => {
              return (
                <span
                  key={icombo}
                  className={` ${
                    comboseleccionado === combo._id['$oid'] ? 'spanseleccionado' : 'porseleccionar'
                  }`}
                  onClick={() => this.setState({ comboseleccionado: combo._id['$oid'] })}
                >
                  {combo.titulo}
                </span>
              )
            })}
          </div>
        </div>
      )
    }
  }

  eliminarElemento(id, tipo) {
    const { user } = this.state
    this.setState({ loadingCategorias: true })
    return fetch(
      `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_eliminarElemento?id=${id}&tipo=${tipo}`
    )
      .then(res => res.json())
      .then(res => {
        this._handleGetCategorias(user.rut)
        if (tipo !== 'producto') {
          this.setState({
            visualizacion: 'default',
            nombresubcategoria: '',
            nombrecategoria: '',
            comboseleccionado: '',
            categoriaseleccionada: '',
          })
        }
        return toast.success('Eliminado exitosamente', this.state.toaststyle)
      })
      .catch(error => {
        this.setState({ loadingCategorias: false })
        toast.error('Ocurrió un problema al realizar esta operación', this.state.toaststyle)
      })
  }

  nuevaCategoria() {
    return this.setState({ visualizacion: 'nuevacategoria' })
  }

  nuevaSubCategoria() {
    return this.setState({ visualizacion: 'nuevasubcategoria' })
  }

  tipoVisualizacion() {
    const { visualizacion, visualizarlistadoproductos, loadingCategorias } = this.state

    if (loadingCategorias)
      return (
        <div className='loading3'>
          <img alt='loading' src='loading.gif' />
          <h3>Cargando...</h3>
        </div>
      )

    if (visualizarlistadoproductos === true) return this.mostrarBuscadorProductos()
    if (visualizacion === 'default') {
      //this.setState({ nombrecategoria: '' })
      return this.mostrarCombos()
    }
    if (visualizacion === 'nuevacategoria') return this.formularioNuevaCategoria()
    if (visualizacion === 'nuevasubcategoria') return this.formularioNuevaSubCategoria()
    if (visualizacion === 'nuevoproducto') return this.formularioNuevoProducto()
    // if (visualizacion === 'pedidos') return this.vistaPedidos()
    // if (visualizacion === 'caja') return this.vistaCaja()
  }

  mostrarBuscadorProductos() {
    const { filter, productos } = this.state
    const lowercasedFilter = filter.toLowerCase()
    const productosFiltrados = productos.filter(item => {
      return Object.keys(item).some(key =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      )
    })
    return (
      <div className='contener mt-4'>
        <span onClick={() => this.setState({ visualizarlistadoproductos: false })}>
          <i className='fas fa-arrow-left'></i> Atrás
        </span>

        <div className='cuadrobuscador'>
          <input
            className='form-control'
            placeholder='BUSCAR PRODUCTOS'
            value={filter}
            onChange={this.handleChange}
          />
          <div className='contenedor-productos'>
            {productosFiltrados.map((info, i) => {
              return (
                <div
                  key={'filtrados' + i}
                  className='boxproducto'
                  onClick={() => this.seleccionarProducto(info, i)}
                >
                  <h4 className='left'> {info.nombre} </h4>
                  <p className='hide'>{info.codigo} </p>{' '}
                  <h4 className='right'>{Intl.NumberFormat(['ban', 'id']).format(info.precio)} </h4>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  seleccionarProducto(producto, i) {
    const { tiponuevoproducto, cat } = this.state
    producto.precio_regular = producto.precio
    producto.titulo = producto.nombre
    this.state.productos.splice(i, 1)
    if (tiponuevoproducto === 'nuevo') {
      return this.setState({
        visualizarlistadoproductos: false,
        tipoproducto: 'variable',
        temporalproducto: producto,
        nombreproducto: producto.nombre,
        precio_regular: producto.precio,
        precio_oferta: producto.precio_oferta,
      })
    } else if (tiponuevoproducto === 'variable') {
      const { arrayopciones } = this.state

      if (!arrayopciones[cat].opciones) arrayopciones[cat].opciones = []
      arrayopciones[cat].opciones.push(producto)
      return this.setState({ visualizarlistadoproductos: false, arrayopciones })
    }
  }

  crearProducto() {
    const {
      nombreproducto,
      user,
      tipoproducto,
      precio_regular,
      temporalproducto,
      precio_oferta,
      arrayitems,
      arrayopciones,
      comboseleccionado,
    } = this.state

    //console.log(this.state)

    if (!tipoproducto || !nombreproducto || !precio_regular)
      return toast.error('Faltan campos por rellenar', this.state.toaststyle)

    let nuevoproducto = {
      idcategoria: comboseleccionado,
      tipo: tipoproducto,
      titulo: nombreproducto,
      precio_regular: precio_regular ? precio_regular.toString() : '',
      precio_oferta: precio_oferta ? precio_oferta.toString() : '',
      propietario: user.rut,
    }

    //console.log(nuevoproducto)

    if (tipoproducto === 'simple') {
      if (temporalproducto) {
        if (temporalproducto.familia_id)
          nuevoproducto.familia_id = temporalproducto.familia_id.toString()
        if (temporalproducto.linea_id) nuevoproducto.linea_id = temporalproducto.linea_id.toString()
        if (temporalproducto.marca_id) nuevoproducto.marca_id = temporalproducto.marca_id.toString()
        if (temporalproducto.sub_familia_id)
          nuevoproducto.sub_familia_id = temporalproducto.sub_familia_id.toString()
        if (temporalproducto.tipo_producto_id)
          nuevoproducto.tipo_producto_id = temporalproducto.tipo_producto_id.toString()
        if (temporalproducto.unidad_medida_id)
          nuevoproducto.unidad_medida_id = temporalproducto.unidad_medida_id.toString()
        if (temporalproducto.codigo) nuevoproducto.codigo = temporalproducto.codigo.toString()
      }
    }

    if (tipoproducto === 'agrupado') {
      if (arrayitems.length < 1)
        return toast.error(
          'Especifica al menos un item dentro de este producto',
          this.state.toaststyle
        )
    }

    if (tipoproducto === 'agrupado') {
      nuevoproducto.detalle = arrayitems
    } else if (tipoproducto === 'variable') {
      nuevoproducto.opciones = arrayopciones
    }

    this.setState({ loadingCategorias: true })
    return fetch(
      'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_crearProducto',
      {
        method: 'POST',
        body: JSON.stringify(nuevoproducto),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())
      .then(res => {
        this._handleGetCategorias(user.rut)
        this.setState({
          visualizacion: 'default',
          nombresubcategoria: '',
          nombrecategoria: '',
          nombreproducto: '',
          tipoproducto: '',
          precio_regular: '',
          precio_oferta: '',
          arrayitems: [],
          arrayopciones: [],
          loadingCategorias: false,
        })
        return toast.success('Creado exitosamente', this.state.toaststyle)
      })
      .catch(error => {
        this.setState({ loadingCategorias: false })
        toast.error(
          'Ocurrió un problema al consultar los datos de la empresa',
          this.state.toaststyle
        )
      })
  }

  agregarItem() {
    const { tipoproducto, itemproducto, arrayitems } = this.state

    if (tipoproducto === 'agrupado') {
      if (itemproducto.trim() === '')
        return toast.warn('El nombre del item es necesario', this.state.toaststyle)

      arrayitems.push({
        titulo: itemproducto,
      })
    }

    return this.setState({ arrayitems, itemproducto: '' })
  }

  opcionesAdicionalesProducto() {
    const { tipoproducto, itemproducto, nuevaopcion, cantidadopcion, arrayopciones, arrayitems } =
      this.state

    if (tipoproducto === 'agrupado')
      return (
        <div>
          <h2 style={{ marginTop: 0 }}>Selecciona los items que componen este producto</h2>
          <div className='row'>
            <div className='col-md-7'>
              <div className='form-group'>
                <label className='form-control-label'>Nombre</label>
                <input
                  className='form-control'
                  name='itemproducto'
                  value={itemproducto}
                  onChange={this.handleChangeState}
                />
              </div>
            </div>

            <div className='col-md-5'>
              <div className='form-group'>
                <label className='form-control-label' style={{ display: 'block' }}>
                  Click para agregar
                </label>
                <button className='btn btn-primary' onClick={() => this.agregarItem()}>
                  AGREGAR
                </button>
              </div>
            </div>
          </div>

          {arrayitems.length > 0 ? (
            <h1 style={{ marginTop: 0 }}>{arrayitems.length} items</h1>
          ) : (
            false
          )}

          {arrayitems.map((item, it) => {
            return (
              <h1 key={it} className='etiqueta2'>
                {item.titulo}{' '}
                <button
                  className='btn btn-eliminar'
                  style={{ marginLeft: 15 }}
                  onClick={() => this.removerAgrupado(it)}
                >
                  ELIMINAR
                </button>
              </h1>
            )
          })}
        </div>
      )

    if (tipoproducto === 'variable')
      return (
        <div style={{ padding: '15px' }}>
          <h2 style={{ marginTop: 0 }}>Selecciona los items que componen este producto</h2>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='form-control-label'>Nombre</label>
                <input
                  className='form-control'
                  name='nuevaopcion'
                  value={nuevaopcion}
                  onChange={this.handleChangeState}
                />
              </div>
            </div>

            <div className='col-md-3'>
              <div className='form-group'>
                <label className='form-control-label'>Cantidad</label>
                <input
                  className='form-control'
                  name='cantidadopcion'
                  type='number'
                  value={cantidadopcion}
                  onChange={this.handleChangeState}
                />
              </div>
            </div>

            <div className='col-md-3'>
              <div className='form-group'>
                <label className='form-control-label' style={{ display: 'block' }}>
                  Click para agregar
                </label>
                <button className='btn btn-primary' onClick={() => this.agregarOpcion()}>
                  AGREGAR
                </button>
              </div>
            </div>
          </div>

          {arrayopciones ? (
            <div>
              {arrayopciones.map((opcion, iop) => {
                return (
                  <div
                    key={`tit-${iop}`}
                    style={{ border: '8px solid #fff', borderRadius: '8px', marginTop: '10px' }}
                  >
                    <div className='row justify-content-start' style={{ padding: 3 }}>
                      <div className='col'>
                        <h1 className='subtitulo mb-0'>
                          {opcion.cantidad} - {opcion.titulo}{' '}
                        </h1>
                        <p className='mb-0'>Agrega las opciones</p>
                        <button
                          className='btn btn-agregar'
                          onClick={() =>
                            this.setState({
                              visualizarlistadoproductos: true,
                              tiponuevoproducto: 'variable',
                              cat: iop,
                            })
                          }
                        >
                          BUSCAR PRODUCTOS
                        </button>
                        <button
                          className='btn btn-eliminar'
                          style={{ marginLeft: 15 }}
                          onClick={() => {
                            this.setState({
                              productos: [...opcion.opciones, ...this.state.productos],
                            })
                            this.remover(iop)
                          }}
                        >
                          ELIMINAR
                        </button>
                      </div>
                    </div>

                    {opcion.opciones.map((subop, isubop) => {
                      return (
                        <h1 key={`isub${isubop}`} className='etiqueta2'>
                          {subop.titulo}{' '}
                          <button
                            className='btn btn-eliminar'
                            onClick={() => this.removersub(iop, isubop)}
                          >
                            ELIMINAR
                          </button>
                        </h1>
                      )
                    })}

                    {/* <div className='row'>
                      <div className='col-md-9'>
                        <div className='form-group'>
                          <label className='form-control-label'>Nombre</label>
                          <input
                            className='form-control'
                            name='nombreitem'
                            onChange={this.handleChangeState}
                          />
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label className='form-control-label' style={{ display: 'block' }}>
                            Click para agregar
                          </label>
                          <button
                            className='btn btn-primary'
                            onClick={() => this.agregarOpcionTitulo(iop)}
                          >
                            AGREGAR
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )
              })}
            </div>
          ) : (
            <h3>Sin opciones creadas</h3>
          )}
        </div>
      )
  }

  removerAgrupado(pos) {
    const { arrayitems } = this.state
    arrayitems.splice(pos, 1)
    return this.setState({ arrayitems })
  }

  removersub(pos, subpos) {
    const { arrayopciones } = this.state
    this.state.productos.unshift(arrayopciones[pos].opciones[subpos])
    arrayopciones[pos].opciones.splice(subpos, 1)
    return this.setState({ arrayopciones })
  }

  remover(pos) {
    const { arrayopciones } = this.state
    arrayopciones.splice(pos, 1)
    return this.setState({ arrayopciones })
  }

  agregarOpcionTitulo(pos) {
    const { arrayopciones, nombreitem } = this.state

    if (!arrayopciones[pos]) return toast.error('Ha ocurrido un error', this.state.toaststyle)

    arrayopciones[pos].opciones.push({
      titulo: nombreitem,
    })
    return this.setState({ arrayopciones })
  }

  agregarOpcion() {
    const { nuevaopcion, cantidadopcion, arrayopciones } = this.state

    if (parseInt(cantidadopcion) < 1)
      return toast.error('Agrega al menos una cantidad a este item', this.state.toaststyle)
    if (nuevaopcion.trim() === '')
      return toast.error('El nombre del item es requerido', this.state.toaststyle)
    arrayopciones.push({
      titulo: nuevaopcion,
      cantidad: cantidadopcion.toString(),
      opciones: [],
    })

    return this.setState({ arrayopciones, nuevaopcion: '', cantidadopcion: 0 })
  }

  formularioNuevoProducto() {
    const {
      categoriaseleccionada,
      combos,
      nombreproducto,
      tipoproducto,
      precio_regular,
      precio_oferta,
      comboseleccionado,
    } = this.state

    //console.log(tipoproducto)
    if (!categoriaseleccionada) return false

    const findi = combos.findIndex(ca => ca._id['$oid'] === categoriaseleccionada)

    if (findi > -1) {
      if (!combos[findi].categorias) return this.productosVacios()

      const posicionproductos = combos[findi].categorias.findIndex(
        ca => ca._id['$oid'] === comboseleccionado
      )

      if (posicionproductos > -1) {
        const seleccionado = combos[findi].categorias[posicionproductos]

        return (
          <div className='contener mt-4'>
            <span
              onClick={() =>
                this.setState({
                  visualizacion: 'default',
                  temporalproducto: false,
                  nombreproducto: '',
                  tipoproducto: '',
                  precio_regular: '',
                  precio_oferta: '',
                  itemproducto: '',
                  nuevaopcion: '',
                  cantidadopcion: '',
                  arrayopciones: '',
                  arrayitems: '',
                })
              }
            >
              <i className='fas fa-arrow-left'></i> Atrás
            </span>
            <h4 className='etiqueta'>Categoría seleccionada: {seleccionado.titulo}</h4>
            <div style={{ marginTop: '30px' }}></div>
            <h2 style={{ fontWeight: 700, marginTop: 4 }}>Crear producto</h2>

            <div className='row'>
              <div className='col-md-10'>
                <div className='form-group'>
                  <label className='form-control-label'>Nombre</label>
                  <input
                    className='form-control'
                    disabled
                    name='nombreproducto'
                    value={nombreproducto}
                    onChange={this.handleChangeState}
                  />
                </div>
              </div>
              <div className='col-md-2 align-self-end'>
                <button
                  className='btn btn-agregar'
                  onClick={() =>
                    this.setState({ visualizarlistadoproductos: true, tiponuevoproducto: 'nuevo' })
                  }
                >
                  BUSCAR PRODUCTOS
                </button>
              </div>

              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='form-control-label'>Precio regular</label>
                  <input
                    className='form-control'
                    name='precio_regular'
                    type='number'
                    value={precio_regular}
                    onChange={this.handleChangeState}
                  />
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='form-control-label'>Precio Oferta</label>
                  <input
                    className='form-control'
                    name='precio_oferta'
                    type='number'
                    value={precio_oferta}
                    onChange={this.handleChangeState}
                  />
                </div>
              </div>

              <div className='col-md-4'>
                <div className='form-group'>
                  <label className='form-control-label'>Tipo</label>
                  <select
                    className='form-control'
                    name='tipoproducto'
                    value={tipoproducto}
                    onChange={this.handleChangeState}
                  >
                    <option value=''>Seleccione</option>
                    <option value='simple'>Simple</option>
                    {/* <option value='agrupado'>Agrupado</option> */}
                    <option value='variable'>Variable</option>
                  </select>
                </div>
              </div>
            </div>

            {this.opcionesAdicionalesProducto()}

            <button className='btn btn-success mt-2 ' onClick={() => this.crearProducto()}>
              CREAR
            </button>
          </div>
        )
      }
    }
    /*
        if(findi > -1){

                const seleccionado = combos[findi]
                return <div className="contener">
                <span onClick={()=>this.setState({ visualizacion: 'default' })} ><i className="fas fa-arrow-left"></i> Atrás</span>
                <h4 className="etiqueta">Categoría seleccionada: {seleccionado.titulo}</h4>
                <h3>Crear producto</h3>

                <div className="form-group">
                    <label className="form-control-label">Nombre</label>
                    <input className="form-control" name="nombreproducto" value={nombreproducto} onChange={this.handleChangeState} />
                </div>

                <div className="form-group">
                    <label className="form-control-label">Tipo</label>
                    <select className="form-control" name="tipoproducto" value={tipoproducto} onChange={this.handleChangeState} >
                        <option value="">Seleccione</option>
                        <option value="simple">Simple</option>
                        <option value="agrupado">Agrupado</option>
                        <option value="variable">Variable</option>
                    </select>
                </div>

                <div className="row">

                <div className="col-md-6">

                <div className="form-group">
                    <label className="form-control-label">Precio regular</label>
                    <input className="form-control" type="number" name="precio_regular" value={precio_regular} onChange={this.handleChangeState} />
                </div>

                </div>

                <div className="col-md-6">
                <div className="form-group">
                    <label className="form-control-label">Precio Oferta</label>
                    <input className="form-control" type="number" name="precio_oferta" value={precio_oferta} onChange={this.handleChangeState} />
                </div>

                </div>

                </div>

                {this.opcionesAdicionalesProducto()}

                <button className="btn btn-success" onClick={() => this.crearProducto()} >CREAR</button>
                </div>

        }
        */
  }

  formularioNuevaSubCategoria() {
    const { categoriaseleccionada, combos, nombresubcategoria } = this.state

    if (!categoriaseleccionada) return false

    const findi = combos.findIndex(ca => ca._id['$oid'] === categoriaseleccionada)

    if (findi > -1) {
      const seleccionado = combos[findi]
      return (
        <div className='contener mt-4'>
          <span onClick={() => this.setState({ visualizacion: 'default', nombresubcategoria: '' })}>
            <i className='fas fa-arrow-left'></i> Atrás
          </span>
          <h4 className='etiqueta'>Categoría seleccionada: {seleccionado.titulo}</h4>
          <h3>Nueva sub categoría</h3>

          <div className='form-group'>
            <label className='form-control-label'>Nombre</label>
            <input
              className='form-control'
              name='nombresubcategoria'
              value={nombresubcategoria}
              onChange={this.handleChangeState}
            />
          </div>

          <button className='btn btn-success mt-2' onClick={() => this.crearSubCategoria()}>
            CREAR
          </button>
        </div>
      )
    }
  }

  formularioNuevaCategoria() {
    const { nombrecategoria } = this.state
    return (
      <div className='contener mt-4'>
        <span onClick={() => this.setState({ visualizacion: 'default', nombrecategoria: '' })}>
          <i className='fas fa-arrow-left'></i> Atrás
        </span>
        <h3 className='mt-2'>Nueva categoría</h3>

        <div className='form-group'>
          <label className='form-control-label'>Nombre</label>
          <input
            className='form-control'
            name='nombrecategoria'
            value={nombrecategoria}
            onChange={this.handleChangeState}
          />
        </div>

        <button className='btn btn-success mt-2' onClick={() => this.crearCategoria()}>
          CREAR
        </button>
      </div>
    )
  }

  crearCategoria() {
    const { nombrecategoria, user } = this.state

    if (!nombrecategoria)
      return toast.warn('El nombre de la categoría es requerido', this.state.toaststyle)
    this.setState({ loadingCategorias: true })
    return fetch(
      'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_crearCategoria',
      {
        method: 'POST',
        body: JSON.stringify({
          titulo: nombrecategoria,
          imagen: '',
          status: '1',
          propietario: user.rut,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())
      .then(res => {
        this._handleGetCategorias(user.rut)
        this.setState({ visualizacion: 'default', nombresubcategoria: '', nombrecategoria: '' })
        return toast.success('Creado exitosamente', this.state.toaststyle)
      })
      .catch(error => {
        this.setState({ loadingCategorias: false })
        toast.error(
          'Ocurrió un problema al consultar los datos de la empresa',
          this.state.toaststyle
        )
      })
  }

  crearSubCategoria() {
    const { nombresubcategoria, user, categoriaseleccionada } = this.state

    if (!nombresubcategoria)
      return toast.warn('El nombre de la categoría es requerido', this.state.toaststyle)
    this.setState({ loadingCategorias: true })
    return fetch(
      'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_crearSubCategoria',
      {
        method: 'POST',
        body: JSON.stringify({
          idcategoria: categoriaseleccionada,
          titulo: nombresubcategoria,
          imagen: '',
          status: '1',
          propietario: user.rut,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())
      .then(res => {
        this._handleGetCategorias(user.rut)
        this.setState({ visualizacion: 'default', nombresubcategoria: '', nombrecategoria: '' })
        return toast.success('Creado exitosamente', this.state.toaststyle)
      })
      .catch(error => {
        this.setState({ loadingCategorias: false })
        toast.error(
          'Ocurrió un problema al consultar los datos de la empresa',
          this.state.toaststyle
        )
      })
  }

  mostrarCombos() {
    const { combos, loadingCombos, categoriaseleccionada, comboseleccionado } = this.state

    if (loadingCombos) return <h4 className='loading'>Cargando</h4>

    return (
      <div>
        <div className='scrollmenu'>
          <span className='porseleccionar' onClick={() => this.nuevaCategoria()}>
            <i className='fas fa-plus-circle'></i> NUEVO
          </span>
          {combos.length > 0
            ? combos.map((combo, icombo) => {
                return (
                  <span
                    key={icombo}
                    className={` ${
                      categoriaseleccionada === combo._id['$oid']
                        ? 'seleccionado'
                        : 'porseleccionar'
                    }`}
                    onClick={() =>
                      this.setState({
                        categoriaseleccionada: combo._id['$oid'],
                        comboseleccionado: '',
                      })
                    }
                  >
                    {combo.titulo}
                  </span>
                )
              })
            : false}
        </div>

        {this.mostrarSubCategoria(categoriaseleccionada)}
        <hr className='hr' />

        <div className='detalleproductospos'>{this.mostrarProductosCombo(comboseleccionado)}</div>
      </div>
    )
  }

  handleChangeReporte(e) {
    const { name, value } = e.target
    if (name === 'mesreporte') {
      return this.setState({ [name]: this.convertMonth(parseInt(value)) })
    }
    this.setState({ [name]: value.toString() })
  }

  subFields = formula => {
    let swappedFormula = _clone(formula)
    const variables = swappedFormula.match(/\{[^\{\}]+\}/gi) || []
    const variablesArray = variables.map(myVariableWithBrackets => {
      return myVariableWithBrackets.slice(1, -1)
    })
    variablesArray.map(myVariable => {
      swappedFormula = swappedFormula.replace(
        new RegExp('{' + _escapeRegExp(myVariable) + '}', 'gi'),
        '1' //when swapping with real value, make sure to cast whatever this value is as a Number to prevent bad code
      )
    })
    return swappedFormula
  }

  getResult = mathString => {
    let result
    try {
      result = math.eval(mathString)
    } catch (err) {
      result = ''
    }
    return result
  }

  /*

      */

  handleChangeProducto(e) {
    const { name, value } = e.target
    const { carrito } = this.state
    carrito[name].precio = value
    return this.setState({ carrito: carrito })
  }

  obtenerUltimaCuadratura(propietario) {
    this.setState({ loadingCuadratura: true })
    return fetch(
      `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_getUltimaCuadratura?propietario=${propietario}`
    )
      .then(res => res.text())
      .then(res => {
        const orders = JSON.parse(res)
        if (orders === '[]') {
          return this.setState({ ultimacuadratura: false, loadingCuadratura: false })
        }

        const jsonpedidos = JSON.parse(orders)
        //console.log(jsonpedidos)
        return this.setState({ ultimacuadratura: jsonpedidos, loadingCuadratura: false })
      })
      .catch(error => {
        this.setState({ loadingCuadratura: false })
        toast.error('Ocurrió un problema al realizar esta operación', this.state.toaststyle)
      })
  }

  // getCategorias(propietario) {
  //   this.setState({ loadingCategorias: true })
  //   return fetch(
  //     `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_categorias?propietario=${propietario}`
  //   )
  //     .then(categorias => categorias.json())
  //     .then(datos => {
  //       //                const datos = JSON.parse(categorias)
  //       //console.log(datos)

  //       this.setState({ loadingCategorias: false, combos: datos })
  //     })
  //     .catch(error => {
  //       this.setState({ loadingCategorias: false })
  //       toast.error(
  //         'Ocurrió un problema al consultar los datos de la empresa',
  //         this.state.toaststyle
  //       )
  //     })
  // }

  // getDatosEmpresa(token) {
  //   return fetch('http://api.softnet.cl/datoEmpresa', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       token: token,
  //     },
  //   })
  //     .then(datosempresa => datosempresa.json())
  //     .then(datosempresa => {
  //       localStorage.setItem('business', JSON.stringify(datosempresa[0]))
  //       this.setState({ datosempresa: datosempresa[0] })
  //     })
  //     .catch(error => {
  //       toast.error(
  //         'Ocurrió un problema al consultar los datos de la empresa',
  //         this.state.toaststyle
  //       )
  //     })
  // }

  // getProductos(token) {
  //   return fetch('http://api.softnet.cl/producto', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       token: token,
  //     },
  //   })
  //     .then(pros => pros.json())
  //     .then(pros => {
  //       //console.log(pros, 'productooooooooss')
  //       localStorage.setItem('productos', JSON.stringify(pros))
  //       this.setState({ productos: pros, loading: false })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  // getBodega(token) {
  //   return fetch('http://api.softnet.cl/bodega', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       token: token,
  //     },
  //   })
  //     .then(bod => bod.json())
  //     .then(bod => {
  //       //console.log(bod)
  //       this.setState({ bodega: bod, inputBodega: bod[0].id })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  // async getGruposTipoPago() {
  //   return fetch(
  //     'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_ListGruposFormaPago'
  //   )
  //     .then(grupos => grupos.json())
  //     .then(grupos => {
  //       this.setState({ gruposPago: JSON.parse(grupos), inputGrupo: JSON.parse(grupos)[0].code })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  // async getTipoPagos() {
  //   //const user = JSON.parse(localStorage.getItem('user'))
  //   return fetch(
  //     `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_ListFormasPago?rut=${this.user.rut}`
  //   )
  //     .then(pagos => pagos.json())
  //     .then(pagos => {
  //       //console.log(JSON.parse(pagos), 'pagos')
  //       this.setState({ formaspago: JSON.parse(pagos) })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  // getVendedores(token) {
  //   return fetch('http://api.softnet.cl/vendedores', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       token: token,
  //     },
  //   })
  //     .then(vend => vend.json())
  //     .then(vend => {
  //       //console.log(vend, 'vend')
  //       this.setState({ vendedores: vend, inputVendedor: vend[0].rut_vendedor })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  // getAreaNegocio(token) {
  //   return fetch('http://api.softnet.cl/areaNegocio', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       token: token,
  //     },
  //   })
  //     .then(area => area.json())
  //     .then(area => {
  //       this.setState({ areaNegocio: area, inputAreaNegocio: area[0].id })
  //     })
  //     .catch(error => {
  //       //console.log(error)
  //     })
  // }

  agregarComboACarrito(producto) {
    const { carrito } = this.state
    if (producto.tipo === 'simple') {
      const costo = producto.precio_oferta
        ? parseFloat(producto.precio_oferta)
        : parseFloat(producto.precio_regular)

      for (let i = 0; i < carrito.length; i++) {
        if (carrito[i]._id == producto._id.$oid) {
          carrito[i].Cantidad = (carrito[i].Cantidad * 1 + 1).toString()
        }
      }
      if (carrito.findIndex(product => product._id === producto._id.$oid) === -1) {
        carrito.push({
          ...producto,
          _id: producto._id.$oid,
          nombre: producto.titulo,
          Cantidad: '1',
          precio: costo,
          Precio: costo,
          codigo: 1,
          estado: 1,
          exento: '0',
        })
      }
      this.setState({ cargandoboleta: false, carrito, filter: '' })
      this.setState({
        efectivo: carrito.reduce(
          (prev, cur) => prev + parseInt(cur.precio * (cur.Cantidad * 1 || 1)),
          0
        ),
      })
      return localStorage.setItem('carrito', JSON.stringify(carrito))
    } else {
      console.log(producto)
      const showModal = producto.opciones.find(({ opciones }) => opciones.length > 1)
      if (showModal) {
        this.setState({
          seleccionvariable: {
            ...producto,
            _id: producto._id.$oid,
          },
        })
        return
      }
      const newOptions = producto.opciones.map(subProducto => ({
        ...subProducto,
        opciones: subProducto.opciones.map(option => ({
          ...option,
          cantidad: subProducto.cantidad,
        })),
      }))
      this.setState({
        carrito: [
          ...carrito,
          {
            ...producto,
            _id: producto._id.$oid,
            opciones: newOptions,
            nombre: producto.titulo,
            precio: producto.precio_oferta
              ? parseFloat(producto.precio_oferta)
              : parseFloat(producto.precio_regular),
            detalle: newOptions,
          },
        ],
        cargandoboleta: false,
        filter: '',
        seleccionvariable: false,
      })
      localStorage.setItem(
        'carrito',
        JSON.stringify([
          ...carrito,
          {
            ...producto,
            _id: producto._id.$oid,
            opciones: newOptions,
            nombre: producto.titulo,
            precio: producto.precio_oferta ? producto.precio_oferta : producto.precio_regular,
            detalle: newOptions,
          },
        ])
      )
    }
  }

  mostrarOpcionesProductoAgrupado() {
    const { seleccionvariable } = this.state

    if (!seleccionvariable) return false

    return (
      <div className='opcionescustom'>
        <span
          className='mt-4'
          style={{ display: 'block' }}
          onClick={() => this.setState({ seleccionvariable: false })}
        >
          <i className='fas fa-arrow-left'></i> Atrás
        </span>
        <h1 className='subtitulo'>Agregar {seleccionvariable.titulo} </h1>
        <h1>
          $
          {seleccionvariable.precio_oferta
            ? Intl.NumberFormat(['ban', 'id']).format(seleccionvariable.precio_oferta)
            : Intl.NumberFormat(['ban', 'id']).format(seleccionvariable.precio_regular)}{' '}
          {seleccionvariable.precio_oferta ? <b style={{ color: 'red' }}>OFERTA</b> : false}{' '}
        </h1>
        {seleccionvariable.opciones
          ? seleccionvariable.opciones.map((opt, iopt) => {
              return (
                <div key={iopt} className='mt-2'>
                  <h3 style={{ fontSize: 17, marginBottom: 0 }}>
                    {opt.cantidad} {opt.titulo}
                  </h3>
                  <div className='row'>
                    {opt.opciones ? (
                      opt.opciones.map((opcion, io) => {
                        return (
                          <div key={io} className='col-md-3'>
                            {/*<span style={{ marginRight: 10 }} className={`etiqueta ${opcion.seleccionado === true ? 'etiquetaseleccionada' : false }` } onClick={()=>this.activar(iopt,io,parseInt(opt.cantidad),opt.titulo)}>{opcion.titulo}</span>*/}
                            <label className='form-control-label' style={{ fontSize: 11 }}>
                              {opcion.titulo}
                            </label>
                            <input
                              cantidad={parseInt(opt.cantidad)}
                              className='form-control'
                              defaultValue={0}
                              io={io}
                              iopt={iopt}
                              max={parseInt(opt.cantidad)}
                              name={opcion.titulo}
                              type='number'
                              value={opcion.cantidad}
                              onChange={this.handleChangeOpcion}
                            />
                          </div>
                        )
                      })
                    ) : (
                      <h3>No hay opciones para elegir</h3>
                    )}
                  </div>
                </div>
              )
            })
          : false}
        <hr className='hr' style={{ marginTop: 22 }} />
        <button className='btn btn-success' onClick={() => this.agregaraCarrito()}>
          AGREGAR
        </button>
      </div>
    )
  }

  agregaraCarrito() {
    const { seleccionvariable, carrito } = this.state

    let errores = []
    // let sumar = 0
    seleccionvariable.opciones.forEach(opcion => {
      const maximo = opcion.cantidad ? parseInt(opcion.cantidad) : 0
      let totalagregado = 0
      opcion.opciones.forEach(item => {
        const qty = item.cantidad ? parseInt(item.cantidad) : 0
        totalagregado = totalagregado + qty
      })
      if (totalagregado < maximo) errores.push(`${opcion.titulo} está incompleto`)
      if (totalagregado > maximo) errores.push(`${opcion.titulo} sobrepasa la cantidad`)
    })

    if (errores.length > 0) return toast.error(errores.join(', '), this.state.toaststyle)

    const producto = seleccionvariable
    producto.nombre = producto.titulo
    producto.precio = producto.precio_oferta ? producto.precio_oferta : producto.precio_regular
    producto.detalle = producto.opciones
    producto.Cantidad = '1'

    carrito.push(producto)
    this.setState({
      efectivo: carrito.reduce(
        (prev, cur) => prev + parseInt(cur.precio * (cur.Cantidad * 1 || 1)),
        0
      ),
    })
    localStorage.setItem('carrito', JSON.stringify(carrito))
    console.log(producto)
    this.setState({ cargandoboleta: false, carrito: carrito, filter: '', seleccionvariable: false })
  }

  /*
    activar(pos, posdos, cantidad, titulo) {
      const { seleccionvariable } = this.state

      seleccionvariable.opciones[pos].opciones[posdos].seleccionado =
        seleccionvariable.opciones[pos].opciones[posdos].seleccionado === true ? false : true

      const cuantosactivos = seleccionvariable.opciones[pos].opciones.filter(
        item => item.seleccionado === true
      )
      if (cuantosactivos.length > cantidad)
        return toast.error(`No puedes escoger más de ${cantidad} ${titulo} `, this.state.toaststyle)

      return this.setState({ seleccionvariable })
    }
    */

  /*
    agregarCarrito(producto) {
      this.state.carrito.push(producto)
      this.setState({ cargandoboleta: false, carrito: this.state.carrito, filter: '' })
      // toast.success(`Agregaste ${producto.nombre} al carrito`, this.state.toaststyle)
      localStorage.setItem('carrito', JSON.stringify(this.state.carrito))
    }
    */

  mostrarCarritoEditar() {
    if (this.state.carrito.length === 0) {
      return <div className='carritoproducto carrito'></div>
    }

    return (
      <div className='carritoproducto carrito'>
        <table>
          <tbody>
            {this.state.carrito.map((pro, i) => {
              return (
                <tr key={'cart' + i} className='productos-unitario'>
                  <td style={{ width: 30 }}>
                    <a href='javascript:void(0)' onClick={() => this.removerDelCarrito(i)}>
                      <i className='fas fa-minus-circle'></i>
                    </a>
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    <p id={i}>
                      {pro.Cantidad} {pro.nombre}{' '}
                    </p>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <h3 className='precio nomargin'>
                      $
                      {Intl.NumberFormat(['ban', 'id']).format(
                        `${pro.precio * (pro?.Cantidad * 1 || 1)}`
                      )}
                    </h3>{' '}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  removerDelCarrito(posicion) {
    let carritoNew = this.state.carrito

    // var removed =
    carritoNew.splice(posicion, 1)

    this.setState({ carrito: carritoNew })
    this.setState({
      efectivo:
        carritoNew.reduce(
          (prev, cur) => prev + parseInt(cur.precio * (cur.Cantidad * 1 || 1)),
          0
        ) ?? 0,
    })
    localStorage.setItem('carrito', JSON.stringify(carritoNew))
  }

  removerDelCola(posicion) {
    let colaNew = this.state.cola

    // var removed =
    colaNew.splice(posicion, 1)

    this.setState({ cola: colaNew })
    localStorage.setItem('cola', JSON.stringify(colaNew))
  }

  componentDidMount() {
    const { /*packs,*/ user } = this.state

    let tokenReceived = user.token
    this._handleGetProductos(tokenReceived)
    // this.getProductos(tokenReceived)
    this._handleGetDatosEmpresa(tokenReceived)
    // this.getDatosEmpresa(tokenReceived)
    this._handleGetGruposTipoPago()
    // this.getGruposTipoPago()
    this._handleGetTipoPagos()
    // this.getTipoPagos()
    this._handleGetBodega(tokenReceived)
    // this.getBodega(tokenReceived)
    this._handleGetVendedores(tokenReceived)
    // this.getVendedores(tokenReceived)
    this._handleGetAreaNegocio(tokenReceived)
    // this.getAreaNegocio(tokenReceived)
    toast.success('Bienvenido', this.state.toaststyle)
    this._handleGetCategorias(user.rut)
    // this.getCategorias(user.rut)
    //this.obtenerPedidos(user.rut, this.formatDate(new Date()))
  }

  handleChange = event => {
    this.setState({ filter: event.target.value })
  }

  handleBodega = event => {
    this.setState({ inputBodega: event.target.value })
  }

  handleVendedor = event => {
    this.setState({ inputVendedor: event.target.value })
  }

  handleTipoPago = event => {
    this.setState({ inputPago: event.target.value })
  }

  handleAreaNegocio = event => {
    this.setState({ inputAreaNegocio: event.target.value })
  }

  handleVuelto = event => {
    this.setState({ efectivo: Intl.NumberFormat(['ban', 'id']).format(event.target.value) })
  }

  sincronizarBoletas() {
    this.setState({ sincronizando: true })
    let cola = JSON.parse(localStorage.getItem('cola'))
    const longitud = cola.length
    cola.forEach((boletaArray, i) => {
      this.syncBoleta(boletaArray)
      if (i + 1 === longitud) {
        return this.setState({ sincronizando: false })
      }
    })
  }

  async syncBoleta(enviarBoleta) {
    let colastate = this.state.cola

    let token = this.state.token.token
    return fetch('http://api.softnet.cl/boleta', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
      body: JSON.stringify(enviarBoleta),
    })
      .then(pros => pros.json())
      .then(async pros => {
        //console.log(pros)
        if (!pros[0]) {
          this.setState({ cargandoboleta: false })

          return false
        }
        // const guardarpedidomongo =
        await this.guardarMongo(enviarBoleta, pros[0])
        var removeIndex = colastate
          .map(function (item) {
            return item[0].fecha
          })
          .indexOf(enviarBoleta[0].fecha)

        colastate.splice(removeIndex, 1)
        this.setState({ cola: colastate })
        localStorage.setItem('cola', JSON.stringify(colastate))

        if (this.state.cola.length === 0) {
          this.setState({ sincronizando: false })
        }
      })
      .catch(error => {
        //console.log(error)
        toast.error('Ocurrió un problema al consultar los datos', this.state.toaststyle)
      })
  }

  imprimir() {
    var printWindow = window.open('', '', 'height=200,width=400')
    printWindow.document.write(`<html><head><title>Resumen de órdenes</title>`)

    //Print the Table CSS.
    var table_style = document.getElementById('table_style').innerHTML
    printWindow.document.write('<style type = "text/css">')
    printWindow.document.write(table_style)
    printWindow.document.write('</style>')
    printWindow.document.write('</head>')

    //Print the DIV contents i.e. the HTML Table.
    printWindow.document.write('<body class="print">')
    var divContents = document.getElementById('boletaimprimir').outerHTML
    printWindow.document.write(divContents)
    printWindow.document.write('</body>')

    printWindow.document.write('</html>')
    printWindow.document.close()
    printWindow.print()
  }

  handleSubmit = async () => {
    this.setState({ cargandoboleta: true })
    const existCuadratura = await this._handleGetActualCuadratura()
    if (existCuadratura) {
      let { carrito, /*formula, TipoDocumento,*/ productos } = this.state
      // event.preventDefault();

      const selectedProducts = [...carrito].map(producto => {
        const codigo = productos.find(({ nombre }) => nombre === producto.nombre).codigo
        return {
          ...producto,
          codigo,
          estado: 1,
          exento: '0',
          Descuento: '0',
          Bodega: this.state.inputBodega,
          Afecto: false,
          Codigo: codigo,
          Precio: Math.ceil(producto.precio / 1.19),
          precio: Math.ceil(producto.precio / 1.19),
        }
      })

      let totalidad = selectedProducts.reduce(
        (prev, cur) => prev + parseFloat(cur.precio * (cur.Cantidad * 1 || 1)),
        0
      )
      let final = totalidad - (totalidad * parseInt(this.state.descuento)) / 100
      const { formaspago, inputAreaNegocio, inputPago, inputGrupo, cliente } = this.state
      const selectedPago = formaspago.find(({ _id }) => _id === inputPago)

      let enviarBoleta = [
        {
          Encabezado: {
            Receptor: '66666666-6',
            MontoNeto: parseInt(final),
            Descuento: 0, //this.state.descuento,
            TipoDocumento: '39',
            AreaNegocio: inputAreaNegocio,
            Observacion: 'observacion',
            Direccion: 'santiago',
          },
          Detalle: selectedProducts,
          cliente: cliente,
          Adicional: {
            Uno: selectedPago.code,
            Dos: this.user.rut,
            Treinta: '123',
            Nueve: selectedPago.nombre,
          },
          fecha: new Date().toLocaleString(),
        },
      ]

      let token = this.state.token.token
      //console.log(JSON.stringify(enviarBoleta))

      if (inputGrupo !== 'convenio') {
        // convenio group not generate boleta
        return fetch('http://api.softnet.cl/boleta', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: token,
          },
          body: JSON.stringify(enviarBoleta),
        })
          .then(pros => pros.json())
          .then(async pros => {
            console.log(pros)

            if (!pros[0]) {
              this.setState({ cargandoboleta: false })
              return toast.error('Error al procesar la boleta', this.state.toaststyle)
            } else if (!pros[0].folio) {
              this.setState({ cargandoboleta: false })
              return toast.error('Error al procesar la boleta', this.state.toaststyle)
            }
            this.setState({ folio: pros[0].folio })
            this.setState({ timbre: pros[0].timbre[0] })
            // const guardarpedidomongo =
            await this.guardarMongo(enviarBoleta, pros[0])
            ///// REINICIAR CARRITO ///////
            /*
                                if(pros[0].timbre){
                                    this.setState({ timbre: pros[0].timbre,  });
                                }
                                */
            this.inputElement.click()
            //this.imprimir()

            setTimeout(() => {
              this.setState({ carrito: [], cargandoboleta: false })
            }, 1000)
            localStorage.setItem('carrito', JSON.stringify([]))
          })
          .catch(error => {
            //console.log(error)
            toast.warn('Documento guardado en la cola', this.state.toaststyle)
            this.inputElement.click()
            ///// GUARDAR BOLETA EN LA COLA DEL NAVEGADOR ///////
            this.state.cola.push(enviarBoleta)
            this.setState({ cola: this.state.cola })
            localStorage.setItem('cola', JSON.stringify(this.state.cola))

            ///// REINICIAR CARRITO ///////
            this.setState({ carrito: [], cargandoboleta: false })
            localStorage.setItem('carrito', JSON.stringify(this.state.carrito))
          })
      }
      this.setState({ folio: 'SIN FOLIO' })
      await this.guardarMongo(enviarBoleta, { folio: 'SIN FOLIO' })
      this.setState({ carrito: [], cargandoboleta: false })
      localStorage.setItem('carrito', JSON.stringify([]))

      return
    }
    this.setState({ cargandoboleta: false })
    toast.error('Opps! Primero debes tener una cuadratura abierta.', this.state.toaststyle)
    return
  }

  async guardarMongo(cuerpo, respuesta) {
    const { user, formaspago, gruposPago, inputPago, inputSerie, inputBanco } = this.state
    const selectedPago = formaspago.find(({ _id }) => _id === inputPago)

    let boleta = cuerpo[0]
    boleta.folio = respuesta.folio
    boleta.respuestaapi = respuesta
    boleta.fecha_string = this.formatDate(new Date())
    boleta.propietario = user.rut
    boleta.serie = selectedPago.extra_data ? inputSerie : ''
    boleta.banco = selectedPago.extra_data ? inputBanco : ''
    boleta.estado = 'generado'
    //console.log(boleta)

    return fetch(
      'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_guardarPedido',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(boleta),
      }
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          efectivo: '',
          inputGrupo: gruposPago[0].code,
          inputPago: '',
          inputSerie: '',
          inputBanco: '',
          step: 1,
        })
        //console.log(res)
        if (respuesta.folio === 'SIN FOLIO')
          toast.success('Pedido generado exitosamente', this.state.toaststyle)
        return res
      })
      .catch(error => {
        //console.log(error)
        return error
      })
  }

  handleDescuento = event => {
    let descuentofinal = 0
    if (event.target.value === '') {
      descuentofinal = 0
    } else {
      descuentofinal = event.target.value
    }
    this.setState({ descuento: descuentofinal })
  }

  handleClient = event => {
    this.setState({ cliente: event.target.value })
  }

  showAll() {
    if (this.state.loading || this.state.TipoDocumento === '') {
      return 'hide'
    }
    return ''
  }

  mostrarColaBoletas() {
    return this.state.cola.map((item, i) => {
      return (
        <div key={'cola' + i} className='productos-unitario'>
          <span key={'boleta' + i} style={{ display: 'inline-block' }}>
            <b>BOLETA - {item[0].fecha}</b>
          </span>
          <a href='javascript:void(0)' onClick={() => this.removerDelCola(i)}>
            <i
              className='fas fa-minus-circle'
              style={{ color: '#fe7701', marginLeft: 5, fontSize: 15 }}
            ></i>
          </a>
        </div>
      )
    })
  }

  buttonSync() {
    if (this.state.sincronizando) {
      return 'Sincronizando...'
    }

    return (
      <a
        className='btn-naranja btn'
        href='javascript:void(0)'
        style={{ marginBottom: '15px' }}
        onClick={() => this.sincronizarBoletas()}
      >
        SINCRONIZAR BOLETAS
      </a>
    )
  }

  verColaBoton() {
    if (this.state.cola.length < 1) {
      return ''
      //return <p>Sin boletas en Cola</p>;
    }

    return (
      <button
        aria-controls='collapseExample'
        aria-expanded='false'
        className='alertaboletas btn btn-primary'
        data-target='#collapseExample'
        data-toggle='collapse'
        type='button'
      >
        !
      </button>
    )
  }

  verCola() {
    if (this.state.cola.length < 1) {
      return ''
      //return <p>Sin boletas en Cola</p>;
    }

    return (
      <div className='col-md-12 col-md-12'>
        <div>
          <div className='collapse padding5' id='collapseExample'>
            <div className='card card-body'>
              {this.mostrarColaBoletas()}
              {this.buttonSync()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  escribir(num) {
    const { formula } = this.state

    return this.setState({ formula: formula + num })
  }

  convertMonth(month) {
    const mes = month.toString()
    if (mes.length < 2) return `0${mes}`

    return mes
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  setVisualizacion(status) {
    return this.setState({ visualizacion: status })
  }

  reporteMensual() {
    const { token, mesreporte, anoreporte } = this.state

    // console.log({
    //   periodo: anoreporte.toString(),
    //   mes: mesreporte.toString(),
    // })
    const clave = token.token
    this.setState({ loadingreporte: true })
    return fetch(`http://api.softnet.cl/informes/ventasPorMes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: clave,
      },
      body: JSON.stringify({
        periodo: anoreporte.toString(),
        mes: mesreporte.toString(),
      }),
    })
      .then(res => res.json())
      .then(datos => {
        let total = 0
        let totaliva = 0
        let totalneto = 0
        let totaldte = 0
        if (datos) {
          if (Array.isArray(datos)) {
            if (datos.length > 0) {
              datos.map(doc => {
                total++
                totaliva = totaliva + doc.valor_i
                totalneto = totalneto + doc.valor_n
                totaldte = totaldte + doc.valor_t
              })
            }
          }
        }

        if (total < 1) {
          this.setState({ loadingreporte: false })
          return toast.warn('Sin documentos para revisar', this.state.toaststyle)
        }

        this.setState({
          loadingreporte: false,
          reportediario: {
            titulo: 'Reporte del mes',
            totalneto: totalneto,
            totaliva: totaliva,
            totaldocumentos: total,
            totalmonto: totaldte,
            fecha: `${this.formatDate(
              new Date()
            )} ${new Date().getHours()}:${new Date().getMinutes()}`,
          },
        })
        return console.log(datos)
      })
      .catch(error => {
        this.setState({ loadingreporte: false })
        //console.log(error)
        toast.error('Ocurrió un problema al consultar los datos', this.state.toaststyle)
      })
  }

  reporteDiario() {
    const { token } = this.state

    const clave = token.token
    this.setState({ loadingreporte: true })
    return fetch(`http://api.softnet.cl/informes/ventasPorDia/${this.formatDate(new Date())}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: clave,
      },
    })
      .then(res => res.json())
      .then(datos => {
        let total = 0
        let totaliva = 0
        let totalneto = 0
        let totaldte = 0
        if (datos) {
          if (Array.isArray(datos)) {
            if (datos.length > 0) {
              datos.map(doc => {
                total++
                totaliva = totaliva + doc.valor_i
                totalneto = totalneto + doc.valor_n
                totaldte = totaldte + doc.valor_t
              })
            }
          }
        }

        if (total < 1) {
          this.setState({ loadingreporte: false })
          return toast.warn('Sin documentos para revisar', this.state.toaststyle)
        }

        this.setState({
          loadingreporte: false,
          reportediario: {
            titulo: 'Reporte del día',
            totalneto: totalneto,
            totaliva: totaliva,
            totaldocumentos: total,
            totalmonto: totaldte,
            fecha: `${this.formatDate(
              new Date()
            )} ${new Date().getHours()}:${new Date().getMinutes()}`,
          },
        })
        return console.log(datos)
      })
      .catch(error => {
        this.setState({ loadingreporte: false })
        //console.log(error)
        toast.error('Ocurrió un problema al consultar los datos', this.state.toaststyle)
      })
  }

  _handleValidateForm() {
    const { efectivo, inputGrupo, inputPago, formaspago, inputSerie, inputBanco } = this.state
    if (efectivo && inputGrupo && inputPago) {
      if (formaspago.find(({ _id }) => _id === inputPago).extra_data)
        return !(inputSerie && inputBanco)

      return false
    }
    return true
  }

  async _handleLogout() {
    if (demoUsers.includes(this.user.rut)) {
      userService.logout()
      this.props.history.push('/login')
    }
    // Validar si existe una cuadratura abierta para no dejar cerrar sesión
    const existCuadratura = await this._handleGetActualCuadratura()
    if (!existCuadratura) {
      userService.logout()
      this.props.history.push('/login')
    }
  }

  async _handleGetActualCuadratura() {
    const response = await cuadraturaService.getActual(this.user.rut)
    return response
  }
  //codigo editado
  async _handleGetProductos(token) {
    const response = await homeService.getProductos(token)
    console.log(response)
    this.setState({ productos: response, loading: false })
  }

  async _handleGetDatosEmpresa(token) {
    const response = await homeService.getDatosEmpresa(token, toast)
    this.setState({ datosempresa: response })
  }
  async _handleGetGruposTipoPago() {
    const response = await homeService.getGruposTipoPago()
    this.setState({ gruposPago: JSON.parse(response), inputGrupo: JSON.parse(response)[0].code })
  }
  async _handleGetTipoPagos() {
    const response = await homeService.getTipoPagos(this.user.rut)
    this.setState({ formaspago: JSON.parse(response) })
  }
  async _handleGetBodega(token) {
    const response = await homeService.getBodega(token)
    this.setState({ bodega: response, inputBodega: response[0].id })
  }
  async _handleGetVendedores(token) {
    const response = await homeService.getVendedores(token)
    this.setState({ vendedores: response, inputVendedor: response[0].rut_vendedor })
  }
  async _handleGetAreaNegocio(token) {
    const response = await homeService.getAreaNegocio(token)
    this.setState({ areaNegocio: response, inputAreaNegocio: response[0].id })
  }
  async _handleGetCategorias(propietario) {
    //this.setState({ loadingCategorias: true })
    const response = await homeService.getCategorias(propietario, toast)
    console.log(propietario)
    this.setState({ loadingCategorias: false, combos: response })
  }

  render() {
    ////////// VALORES ///////////
    let totalidad = this.state.carrito.reduce(
      (prev, cur) => prev + parseInt(cur.precio * (cur.Cantidad * 1 || 1)),
      0
    )

    let final = totalidad - (totalidad * parseInt(this.state.descuento)) / 100
    let vuelto = this.state.efectivo ? parseInt(this.state.efectivo) - final : 0

    const Renderizar = () => {
      if (this.state.loading || this.state.TipoDocumento === '') {
        return (
          <div className='cargando'>
            <div className='loading3 '>
              <img src='loading.gif' />
            </div>
            <h1
              className='nuppy'
              style={{
                marginTop: 40,
                color: 'black',
                fontWeight: 600,
                lineHeight: '10px',
                fontSize: 60,
              }}
            >
              NUPY
            </h1>
          </div>
        )
      }
      return ''
    }

    const {
      cargandoboleta,
      vistapos,
      visualizacion,
      // loadingreporte,
      // anoreporte,
      // mesreporte,
      // reportediario,
      // user,
      inputPago,
      gruposPago,
      inputGrupo,
      step,
      carrito,
      inputSerie,
      inputBanco,
    } = this.state

    return (
      <div>
        <ToastContainer />
        <div className={`celeste  ${this.showAll()}`}>
          <div className='row m-0'>
            <div className='col-md-12 col-md-12 barrasuperior'>
              <h2 className='titulocarrito nomargin titulocart' style={{ display: 'inline' }}>
                NUPY <b className='boletasimple'>SISTEMA POS</b>
              </h2>
              <div style={{ float: 'right' }}>
                {this.agentSuperAdmin()}
                <Button
                  className='text-white'
                  size='sm'
                  style={{ marginLeft: 12 }}
                  variant='link'
                  onClick={this._handleLogout.bind(this)}
                >
                  Cerrar Sesión
                </Button>
              </div>
              {this.verColaBoton()}
            </div>
            {this.verCola()}
          </div>
        </div>
        <div className='azuloscuro'>
          <Renderizar />
          <div className={`${this.showAll()}`}>
            <div className='row nomargin' style={{ '--bs-gutter-x': 0 }}>
              <div className='row' style={{ padding: 0 }}>
                <div className='col-md-12' style={{ paddingRight: 0 }}>
                  <div className='row'>
                    <div
                      className={`${
                        ['reporte', 'pedidos', 'caja'].includes(visualizacion)
                          ? 'col-md-12'
                          : 'col-md-7'
                      }`}
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                      {visualizacion === 'reporte' && (
                        <SeccionReporte
                          onChangeVisualization={value => this.setVisualizacion(value)}
                        />
                      )}
                      {this.tipoVisualizacion()}
                      {visualizacion === 'pedidos' && (
                        <SeccionPedidos
                          onChangeVisualization={value => this.setVisualizacion(value)}
                        />
                      )}
                      {visualizacion === 'caja' && (
                        <SeccionCaja
                          onChangeVisualization={value => this.setVisualizacion(value)}
                        />
                      )}
                    </div>
                    {!['reporte', 'pedidos', 'caja'].includes(visualizacion) && (
                      <div
                        className={`lateral nopadding ${
                          vistapos === true ? 'col-md-5' : 'col-md-12'
                        } `}
                      >
                        <div className='productosenelcarrito'>
                          <div className='contadorproductos'>
                            <h4 className='contadorcart nomargin'>
                              {this.state.carrito.length} Productos en el carrito
                            </h4>
                          </div>
                          {this.mostrarCarritoEditar()}

                          <div className='contener2'>
                            <div className='form-group mb-3'>
                              <label className='form-label' style={{ fontWeight: 'bold' }}>
                                Cliente
                              </label>
                              <input
                                className='form-control'
                                placeholder='Nombre Cliente'
                                onChange={this.handleClient}
                              />
                            </div>

                            {step === 1 && (
                              <div className='rows' style={{ marginBottom: 10 }}>
                                <div className='col-xs-4' style={{ padding: '0 15px' }}>
                                  <label className='mayuscula singrosor form-label'>Monto</label>
                                  <input
                                    className='form-control botontransparente'
                                    placeholder='MONTO'
                                    style={{ borderBottom: '3px solid white', borderRadius: 0 }}
                                    type='number'
                                    value={this.state.efectivo}
                                    onChange={this.handleVuelto}
                                  />
                                </div>

                                <div className='col-xs-4' style={{ padding: '0 15px' }}>
                                  <label className='mayuscula singrosor form-label'>Vuelto</label>
                                  <input
                                    className='form-control botontransparente'
                                    placeholder='VUELTO'
                                    readOnly
                                    value={Intl.NumberFormat(['ban', 'id']).format(vuelto)}
                                  />
                                </div>

                                <div className='col-xs-4' style={{ padding: '0 15px' }}>
                                  <label className='mayuscula singrosor form-label'>Total</label>
                                  <h2 className='monto'>
                                    {Intl.NumberFormat(['ban', 'id']).format(final)}
                                  </h2>
                                </div>
                              </div>
                            )}
                            {step === 2 && (
                              <>
                                <div className='form-group mb-3'>
                                  <label className='form-label' style={{ fontWeight: 'bold' }}>
                                    GRUPO DE PAGO
                                  </label>
                                  <select
                                    className='form-control dropdown-transparent'
                                    placeholder='Select Grupo de Pago'
                                    value={inputGrupo}
                                    onChange={e => {
                                      this.setState({ inputGrupo: e.target.value, inputPago: '' })
                                    }}
                                  >
                                    {gruposPago.map(group => {
                                      return (
                                        <option key={group._id} value={group.code}>
                                          {group.nombre}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                                <div className='form-group mb-3'>
                                  <label className='form-label' style={{ fontWeight: 'bold' }}>
                                    TIPO DE PAGO
                                  </label>
                                  <select
                                    className='form-control dropdown-transparent'
                                    disabled={!inputGrupo}
                                    value={inputPago}
                                    onChange={this.handleTipoPago}
                                  >
                                    <option disabled value={''}>
                                      Seleccione Tipo de Pago
                                    </option>
                                    {this.state.formaspago
                                      .filter(({ grupo }) => grupo === inputGrupo)
                                      .map(tipo => {
                                        return (
                                          <option key={tipo._id} value={tipo._id}>
                                            {tipo.nombre}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </div>
                                {inputPago &&
                                  this.state.formaspago.find(({ _id }) => _id === inputPago)
                                    .extra_data && (
                                    <>
                                      <div className='form-group mb-3'>
                                        <label
                                          className='form-label'
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          SERIE
                                        </label>
                                        <input
                                          className='form-control input-black'
                                          placeholder='INGRESA LA SERIE'
                                          type='number'
                                          value={inputSerie}
                                          onChange={e =>
                                            this.setState({ inputSerie: e.target.value })
                                          }
                                        />
                                      </div>
                                      <div className='form-group mb-3'>
                                        <label
                                          className='form-label'
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          BANCO EMISOR
                                        </label>
                                        <input
                                          className='form-control input-black'
                                          placeholder='INGRESA EL BANCO EMISOR'
                                          value={inputBanco}
                                          onChange={e =>
                                            this.setState({ inputBanco: e.target.value })
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                              </>
                            )}
                            {cargandoboleta ? (
                              <h4 className='loading'>Cargando</h4>
                            ) : step === 1 ? (
                              <div className='div-buttons'>
                                <Button
                                  className='pagar'
                                  style={{ width: '100%' }}
                                  variant='success'
                                  onClick={() => this.setState({ step: step + 1 })}
                                >
                                  CONTINUAR
                                </Button>
                              </div>
                            ) : (
                              <div className='d-flex justify-content-center div-buttons'>
                                <Button
                                  className='button-green'
                                  variant='success'
                                  onClick={() => this.setState({ step: step - 1 })}
                                >
                                  ATRAS
                                </Button>
                                <Button
                                  className='button-green right'
                                  disabled={carrito.length === 0 || this._handleValidateForm()}
                                  variant='success'
                                  onClick={this.handleSubmit}
                                >
                                  FINALIZAR Y PAGAR
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactToPrint
          content={() => this.componentRef}
          trigger={() => (
            <a
              ref={input => (this.inputElement = input)}
              className='hide'
              href='javascript:void(0)'
            >
              IMPRIMIR
            </a>
          )}
        />
        <ComponentToPrint
          ref={el => (this.componentRef = el)}
          carrito={this.state.carrito}
          cliente={this.state.cliente}
          datosempresa={this.state.datosempresa}
          descuento={this.state.descuento}
          folio={this.state.folio}
          show={this.state.cargandoboleta && inputGrupo !== 'convenio'}
          tiempo={new Date().toLocaleString()}
          timbre={this.state.timbre}
          tipodocumento={this.state.TipoDocumento}
          tipopago={this.state.inputPago}
        />
      </div>
    )
  }
}

export { HomePage }
