/* eslint-disable react/jsx-handler-names */
import React from 'react'
import { userService } from '../_services'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button, CircularProgress, Container, Grid, Input, Paper } from '@material-ui/core'
import './Login.sass'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    //userService.logout()

    this.state = {
      username: '',
      password: '',
      rut: '',
      submitted: false,
      loading: false,
      error: '',
      toaststyle: { position: toast.POSITION.TOP_RIGHT },
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  updateRol(admin, cajero) {
    //(admin,cajero)
    if (admin === 1) {
      return 'ADMIN'
    } else if (cajero === 1) {
      return 'VENDEDOR'
    } else {
      return 'ENTREGAR_PEDIDO'
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  async handleSubmit(e) {
    e.preventDefault()

    this.setState({ submitted: true })
    const { username, password, rut } = this.state

    // stop here if form is invalid
    if (!(username && password && rut)) {
      return
    }

    this.setState({ loading: true })
    await userService.login(username, password, rut).then(
      user => {
        if (!user) {
          this.setState({ loading: false })
          return toast.error('Credenciales incorrectas', this.state.toaststyle)
        }
        this.getRoleService(user.token)
      },
      error => {
        toast.error('Credenciales incorrectas', this.state.toaststyle)
        this.setState({ error, loading: false })
      }
    )
  }
  async getRoleService(tokenUser) {
    await userService.dataUser(tokenUser).then(
      dataUser => {
        //console.log(dataUser.cajero)
        const roleName = this.updateRol(dataUser.admin, dataUser.cajero)
        //console.log("RolName: "+ roleName)
        localStorage.setItem('dataUser', JSON.stringify(dataUser).replace(/['"]+/g, ''))
        localStorage.setItem('rol', JSON.stringify(roleName).replace(/['"]+/g, ''))
        localStorage.setItem('email', JSON.stringify(dataUser.email).replace(/['"]+/g, ''))

        const { from } = this.props.location.state || { from: { pathname: '/' } }
        this.props.history.push(from)
      },
      error => {
        toast.error('Error al recuperar la data de usuarios', this.state.toaststyle)
        this.setState({ error, loading: false })
      }
    )
  }
  render() {
    const { username, password, rut, submitted, loading, error } = this.state
    return (
      <div>
        <ToastContainer />
        <Container
        //style={{ paddingTop: '12%' }}
        >
          <Grid alignContent='center' container style={{ height: '100vh' }}>
            <Grid item md={2} />
            <Grid item md={4} style={{ paddingTop: '7vh', paddingBottom: '7vh' }} xs={12}>
              <Grid container>
                <h1
                  className='nuppy '
                  style={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#1976D2',
                    fontWeight: 600,
                    lineHeight: '10px',
                    fontSize: '8em',
                    marginTop: '8vh',
                  }}
                >
                  NUPY
                </h1>
                <p style={{ fontSize: '1.6em', fontWeight: 'bolder', marginTop: '8vh' }}>
                  Hecho para la gestionar tu punto de venta de manera fácil y rápida.
                </p>
              </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid
              item
              md={3}
              style={{
                textAlign: 'left',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              xs={12}
            >
              <Paper elevation={3}>
                <Grid
                  style={{
                    padding: '18px',
                    paddingBottom: '25px',
                    borderTop: '5px solid #1976D2',
                    borderRadius: '5px',
                  }}
                >
                  <center>
                    <h2
                      className='nuppy'
                      style={{
                        color: '#gray',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontSize: '1.6em',
                        marginBottom: 12,
                        marginTop: 12,
                      }}
                    >
                      Bienvenido!
                    </h2>
                  </center>
                  <form name='form' onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                      <Input
                        className='form-control'
                        name='username'
                        placeholder='Usuario'
                        size='small'
                        style={{
                          marginBottom: '20px',
                          background: '#f3f3f3',
                          marginTop: 12,
                          border: 'none',
                        }}
                        type='text'
                        value={username}
                        onChange={this.handleChange}
                      ></Input>
                      {submitted && !username && (
                        <div className='help-block'>Usuario es requerido</div>
                      )}
                    </div>
                    <div className={'form-group' + (submitted && !rut ? ' has-error' : '')}>
                      <Input
                        className='form-control'
                        name='rut'
                        placeholder='RUT'
                        size='small'
                        style={{ marginBottom: '20px', background: '#f3f3f3', border: 'none' }}
                        type='text'
                        value={rut}
                        onChange={this.handleChange}
                      ></Input>
                      {submitted && !rut && <div className='help-block'>Rut es requerido</div>}
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                      <Input
                        className='form-control'
                        name='password'
                        placeholder='Contraseña'
                        size='small'
                        style={{ marginBottom: '20px', background: '#f3f3f3', border: 'none' }}
                        type='password'
                        value={password}
                        onChange={this.handleChange}
                      ></Input>
                      {submitted && !password && (
                        <div className='help-block'>Contraseña es requerida</div>
                      )}
                    </div>
                    <div>
                      <Button
                        className='btn '
                        disabled={loading}
                        style={{
                          width: '100%',
                          background: '#1976D2',
                          color: 'white',
                          borderRadius: '24px',
                          marginTop: 3,
                          marginBottom: 4,
                        }}
                        type='submit'
                      >
                        {loading ? (
                          <CircularProgress className='CircularProgress' size={24} />
                        ) : (
                          <span>Iniciar sesión</span>
                        )}
                      </Button>
                    </div>
                  </form>
                </Grid>
              </Paper>
              {error && <div className={'alert alert-danger'}>{error}</div>}
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export { LoginPage }
