/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import cuadraturaService from '../../../../_services/cuadratura'
import '../../styles.scss'
import '../../../../styles/app.scss'
import { Button,Table} from 'react-bootstrap'
import moment from 'moment'
import DetalleModal from '../../General/detalle-modal'
import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import  TableFooter from '@material-ui/core/TableFooter';
import { esES }  from '@material-ui/core/locale';
import {ThemeProvider,createTheme,makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme)=>({
  table:{
    minWidth:650
  },
  tableContainer:{
    borderRadius:15,
    margin: 'auto auto',
    maxWidth:1250,
  },
  tableHeaderCell:{
    fontWeight:'bold',
    fontSize:16
  },
  tableFotter:{
    alignItems:'center'
  }

}));
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);

const DetalleCuadraturaReport = props => {
  const [fecha_inicial, setFechaInicial] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [fecha_final, setFechaFinal] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const user = JSON.parse(localStorage.getItem('user'))
  const toastStyle = { position: toast.POSITION.TOP_CENTER }
  const [orders, setOrders] = useState([])
  const [orderSelected, setOrderSelected] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState([])
  const [simpleProducts, setSimpleProducts] = useState([])
  const [variableProducts, setVariableProducts] = useState([])
  const [loadingNew, setLoadingNew] = useState(false)
  const [locale1, setLocale1] = useState('es-ES');

  useEffect(() => {
    setFechaInicial(moment().startOf('month').format('YYYY-MM-DD'));
    setFechaFinal(moment().endOf('month').format('YYYY-MM-DD'));
    cuadraturaService.getPedidos(user.rut, fecha_inicial, fecha_final).then(
      response => {
        //console.log(response, ' reporte response by change')
        setOrders(response);
    },
      error => {
        toast.error('Opps! No se pudo procesar la operación', toastStyle)
        setLoadingNew(false)
        this.setState({ error, loading: false })
      }
    )
  }, [])
  const getPedidosByRange = () =>{
    cuadraturaService.getPedidos(user.rut, fecha_inicial, fecha_final).then(
      response => {
        //console.log(response, ' reporte response')
        setOrders(response)
      },
      error => {
        toast.error('Opps! No se pudo procesar la operación', toastStyle)
        setLoadingNew(false)
        this.setState({ error, loading: false })
      }
    )
  }
  useEffect(() => {
    getPedidosByRange();
  }, [fecha_inicial, fecha_final])

  useEffect(() => {
    if (orders) {
      _handleGetPaymentTypes()
      _handleGetSimpleProducts()
      _handleGetVariableProducts()
    }
  }, [orders])

  const _handleGetVariableProducts = () => {
    const variableProductsFormatted = orders.reduce((accVariableProducts, order) => {
      const products = order.Detalle.filter(({ tipo }) => tipo === 'variable')
      if (products.length > 0) {
        products.forEach(product => {
          const productIndex = accVariableProducts.findIndex(({ _id }) => _id === product._id)
          if (productIndex !== -1) {
            const productSaved = accVariableProducts[productIndex]

            accVariableProducts.splice(productIndex, 1, {
              ...productSaved,
              quantity: productSaved.quantity + 1,
              subProductos: productSaved.subProductos.map((subProducto, subProductIndex) => {
                return {
                  ...subProducto,
                  opciones: subProducto.opciones.map((option, optionIndex) => {
                    return {
                      ...option,
                      cantidad:
                        option.cantidad +
                        (Number(product.opciones[subProductIndex].opciones[optionIndex].cantidad) ||
                          0),
                    }
                  }),
                }
              }),
            })
          } else {
            accVariableProducts.push({
              _id: product._id,
              name: product.titulo,
              codigo: product.codigo,
              quantity: 1,
              subProductos: product.opciones.map(subProducto => {
                return {
                  ...subProducto,
                  opciones: subProducto.opciones.map(option => ({
                    ...option,
                    cantidad: Number(option.cantidad) || 0,
                  })),
                }
              }),
            })
          }
        })
      }
      return accVariableProducts
    }, [])
    setVariableProducts(variableProductsFormatted)
  }
  const _handleGetSimpleProducts = () => {
    const simpleProductsFormatted = orders.reduce((accSimpleProducts, order) => {
      const products = order.Detalle.filter(({ tipo }) => tipo === 'simple')
      if (products.length > 0) {
        products.forEach(product => {
          const productIndex = accSimpleProducts.findIndex(({ _id }) => _id === product._id)
          if (productIndex !== -1) {
            accSimpleProducts.splice(productIndex, 1, {
              ...accSimpleProducts[productIndex],
              quantity: accSimpleProducts[productIndex].quantity + 1,
            })
          } else {
            accSimpleProducts.push({
              _id: product._id,
              name: product.titulo,
              codigo: product.codigo,
              quantity: 1,
            })
          }
        })
      }
      return accSimpleProducts
    }, [])
    setSimpleProducts(simpleProductsFormatted)
  }
  const priceByPedido = (detallepedido) => {
    let sumPrice = 0
    detallepedido.forEach(element => {
      let priceTmp = parseInt((element.precio_oferta !=='')?element.precio_oferta*element.Cantidad:
      element.precio_regular*element.Cantidad)
      sumPrice += priceTmp
    });
    return sumPrice
  }
  const _handleGetPaymentTypes = () => {
    const payments = orders.reduce((accPayments, order, index) => {
      const payIndex = accPayments.findIndex(({ name }) => name === order.Adicional.Nueve)
      if (payIndex !== -1) {
        const pay = accPayments[payIndex]
        accPayments.splice(payIndex, 1, {
          ...pay,
          ordersQuantity: pay.ordersQuantity + 1,
          totalNeto: pay.totalNeto + (Number(priceByPedido(order.Detalle)) || 0),
        })
        return accPayments
      }

      return accPayments.concat({
        name: order.Adicional.Nueve,
        ordersQuantity: 1,
        totalNeto: Number(priceByPedido(order.Detalle)) || 0,
      })
    }, [])
    setPaymentTypes(payments)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const _handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const _handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes= useStyles();
  return (
    <div className='px-4 py-2'>
      <div className='row align-items-center'>
        <div className='col-lg-12'>
          <div className='d-lg-flex justify-content-between'>
            <div className=''>
              <label className='form-control-label mb-2 date-label cuadraturaTitle'>MES</label>
              <input
                className='form-control cuadraturaTitleName'
                min="2018-01"
                type='month'
                value={moment(fecha_inicial).format('YYYY-MM')}
                onChange={e => {
                  setFechaInicial(moment(e.target.value).startOf('month').format('YYYY-MM-DD'))
                   setFechaFinal(moment(e.target.value).endOf('month').format('YYYY-MM-DD'))
                }
                }
              />
            </div>
          </div>
        </div>
      </div>

      {!orders && <h1 className='text-empty text-center'>NO EXISTE PEDIDOS</h1>}
      {!!paymentTypes && (
        <div>
          <h1 className='subtitle text-bold'>Formas de pago:</h1>
          <Table bordered hover size='sm' striped>
            <thead>
              <tr>
                <th>FORMA DE PAGO</th>
                <th>CANTIDAD</th>
                <th>MONTO TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {paymentTypes.map((paymentType, index) => {
                return (
                  <tr key={`order-${index}`}>
                    <td>{paymentType.name}</td>
                    <td>{paymentType.ordersQuantity}</td>
                    <td>$ {Intl.NumberFormat(['ban', 'id']).format(paymentType.totalNeto)}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}
      {!!orders && (

        <div>
          <h1 className='subtitle text-bold'>Pedidos Vendidos:</h1>
          {/*-----*/}
          <TableContainer className={classes.tableContainer} component={Paper}>
          <ThemeProvider theme={theme}>
            <TableMui aria-label="simple table" className={classes.table} >
              <TableHead >
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} >FOLIO</TableCell>
                  <TableCell className={classes.tableHeaderCell}>PRODUCTOS</TableCell>
                  <TableCell className={classes.tableHeaderCell}>FECHA</TableCell>
                  <TableCell className={classes.tableHeaderCell}>TOTAL</TableCell>
                  {/* <TableCell className={classes.tableHeaderCell}>NETO</TableCell> */}
                  <TableCell className={classes.tableHeaderCell}>ACCIONES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, ipe) => (
                  <TableRow
                    key={ipe}
                  >
                    <TableCell component="th" scope="row" >{order.folio}</TableCell>
                    <TableCell >{order.Detalle.length}</TableCell>
                    <TableCell >{order.fecha}</TableCell>
                    <TableCell > $
                      {Intl.NumberFormat(['ban', 'id']).format(priceByPedido(order.Detalle))
                      }</TableCell>
                    {/* <TableCell > $
                      {Intl.NumberFormat(['ban', 'id']).format(
                        order.Encabezado.MontoNeto.$numberDouble
                      )}</TableCell> */}
                    <TableCell >
                      <Button onClick={() => setOrderSelected(order)}>Ver Detalle</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter >

              </TableFooter>
            </TableMui>
            <TablePagination
                  component="div"
                  count={orders.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5,10,15]}
                  onPageChange={_handleChangePage}
                  onRowsPerPageChange={_handleChangeRowsPerPage}
                />
            </ThemeProvider>
          </TableContainer>
    </div>
      )}
      {!!simpleProducts && (
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <h1 className='subtitle text-bold'>Productos Simples:</h1>
            <Table bordered hover size='sm' striped>
              <thead>
                <tr>
                  <th>CODIGO</th>
                  <th>NOMBRE</th>
                  <th>CANTIDAD</th>
                </tr>
              </thead>
              <tbody>
                {simpleProducts.map((product, index) => {
                  return (
                    <tr key={`product-simple-${index}`}>
                      <td>{product.codigo}</td>
                      <td>{product.name}</td>
                      <td>{product.quantity}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <div className='col-lg-6 col-md-12'>
            <h1 className='subtitle text-bold'>Productos Variables:</h1>
            <Table bordered hover size='sm' striped>
              <thead>
                <tr>
                  <th>CODIGO</th>
                  <th>NOMBRE</th>
                  <th>CANTIDAD</th>
                </tr>
              </thead>
              <tbody>
                {variableProducts.map((product, index) => {
                  return (
                    <tr key={`product-variable-${index}`}>
                      <td>{product.codigo}</td>
                      <td>
                        <p className='producto-nombre-label'>{product.name}</p>
                        {product.subProductos.map((subProducto, subProductoIndex) => (
                          <div key={`subProducto-${subProductoIndex}`}>
                            <p className='producto-option'>- {subProducto.titulo}:&nbsp;&nbsp;</p>
                            {subProducto.opciones.map((option, indexOption) => (
                              <p
                                key={`option-${indexOption}`}
                                className='producto-option'
                                style={{ marginLeft: 20 }}
                              >
                                . {option.titulo}: {option.cantidad}
                              </p>
                            ))}
                          </div>
                        ))}
                      </td>
                      <td>{product.quantity}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <DetalleModal
        open={Boolean(orderSelected)}
        pedido={orderSelected}
        state={''}
        onClose={() => setOrderSelected(null)}
      />
    </div>
  )
}

export { DetalleCuadraturaReport }
