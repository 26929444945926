/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import '../styles.scss'
import '../../../styles/app.scss'
import { DetalleCuadraturaReport } from './components/detalle-cuadratura-report'

const SeccionReporte = props => {
  const { onChangeVisualization: _handleChangeVisualization } = props
  const user = JSON.parse(localStorage.getItem('user'))

  return (
    <div className='contener mt-4'>
      <span onClick={() => _handleChangeVisualization('default')}>
        <i className='fas fa-arrow-left'></i> Atrás
      </span>
      <div className='bg-white mt-4'>
      <DetalleCuadraturaReport />
      </div>
    </div>
  )
}

export { SeccionReporte }
