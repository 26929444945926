export const homeService = {
  getProductos,
  getDatosEmpresa,
  getGruposTipoPago,
  getTipoPagos,
  getBodega,
  getVendedores,
  getAreaNegocio,
  getCategorias
}

async function getProductos(token){
  return fetch('http://api.softnet.cl/producto', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(pros => pros.json())
    .then(pros => {
      //console.log(pros, 'productooooooooss')
      localStorage.setItem('productos', JSON.stringify(pros))
      return pros;
    })
    .catch(error => {
      return false
      //console.log(error)
    })
}

async function getDatosEmpresa(token,toast) {
  return fetch('http://api.softnet.cl/datoEmpresa', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(datosempresa => datosempresa.json())
    .then(datosempresa => {
      localStorage.setItem('business', JSON.stringify(datosempresa[0]))
      return datosempresa[0];
    })
    .catch(error => {
      toast.error(
        'Ocurrió un problema al consultar los datos de la empresa',
        this.state.toaststyle
      )
    })
}

async function getGruposTipoPago() {
  return fetch(
    'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_ListGruposFormaPago'
  )
    .then(grupos => grupos.json())
    .then(grupos => {
      return grupos;
    })
    .catch(error => {
      return null;
      //console.log(error)
    })
}

async function getTipoPagos(rut) {
  //const user = JSON.parse(localStorage.getItem('user'))
  return fetch(
    `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/web_ListFormasPago?rut=${rut}`
  )
    .then(pagos => pagos.json())
    .then(pagos => {
      //console.log(JSON.parse(pagos), 'pagos')
      return pagos;
    })
    .catch(error => {
      return null;
      //console.log(error)
    })
}

async function getBodega(token) {
  return fetch('http://api.softnet.cl/bodega', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(bod => bod.json())
    .then(bod => {
      //console.log(bod)
      return bod;
    })
    .catch(error => {
      return null;
      //console.log(error)
    })
}

async function getVendedores(token) {
  return fetch('http://api.softnet.cl/vendedores', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(vend => vend.json())
    .then(vend => {
      //console.log(vend, 'vend')
      return vend;
    })
    .catch(error => {
      return null;
      //console.log(error)
    })
}

async function getAreaNegocio(token) {
  return fetch('http://api.softnet.cl/areaNegocio', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      token: token,
    },
  })
    .then(area => area.json())
    .then(area => {
      return area;
    })
    .catch(error => {
      return null;
      //console.log(error)
    })
}

async function getCategorias(propietario,toast) {
   // this.setState({ loadingCategorias: true })
    return fetch(
      `https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/nupy-cfbnr/service/restaurant/incoming_webhook/nupy_categorias?propietario=${propietario}`
    )
      .then(categorias => categorias.json())
      .then(datos => {
        //                const datos = JSON.parse(categorias)
        //console.log(datos)
        return datos;
      })
      .catch(error => {
        this.setState({ loadingCategorias: false })
        toast.error(
          'Ocurrió un problema al consultar los datos de la empresa',
          this.state.toaststyle
        )
      })
  }
